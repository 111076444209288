<template>
  <div class="tutor">
    <div class="header">
      <div class="center">
        {{ title }}
      </div>
      <div class="right"></div>
    </div>
    <div class="main" v-if="onloading == false">
      <div class="info-1">{{ text }}</div>
      <div class="img">
        <img :src="wechatUrl" alt="" />
      </div>
      <!-- <div class="btn">
                <button class="copy">
                    截图到微信扫码
                </button>
            </div> -->
      <div class="btn">
        <button class="copy" @click="onCopyPassword">
          长按图片识别二维码
        </button>
        <textarea v-model="wechatId" id="copy-textarea" />
      </div>
    </div>
    <div class="on-loading" v-if="onloading">
      <van-skeleton title :row="5" />
    </div>
    <!-- <div class="footer">
            <footerMenu></footerMenu>
        </div> -->
  </div>
</template>

<script>
import { Notify, Skeleton } from "vant";
import { TouchEmulator } from "@/util/touch.js";
import footerMenu from "@/components/footerMenu.vue";
import { flexQrcodeApi } from "@/api/flexQrcodeApi.js";
import { payApi } from "@/api/payApi.js";
import { orderApi } from "@/api/orderApi.js";
import { courseApi } from "@/api/courseApi.js";
import { wechatInitApi } from "@/api/wechatInitApi.js";
export default {
  components: {
    // footerMenu,
  },
  data() {
    return {
      wechatId: "hepulaocai",
      wechatUrl: "",
      title: "",
      text: "",
      openId: "",
      onloading: true,
    };
  },
  methods: {
    onCopyPassword() {},

    getCode() {
      let id = 2;
      if (this.$route.query && this.$route.query.id) {
        id = this.$route.query.id;
      }

      if (this.$route.query && this.$route.query.code) {
        const code = this.$route.query.code;
        wechatInitApi
          .getOpenId(code)
          .then((ret) => {
            console.log("openid", ret);
            if (ret.code == 0 && ret.data) {
              this.openId = ret.data.openid;
              flexQrcodeApi.wxQrcode(id, this.openId).then((ret) => {
                console.log("wxqrcode", ret);
                this.onloading = false;
                const item = ret.data;
                this.title = item.title;
                this.text = item.text;
                document.title = this.text;
                this.wechatId = item.weChat.wechatId;
                this.wechatUrl = item.weChat.url;
              });
            }
          })
          .catch((err) => {
            this.showCsWidget = true;
            this.csTitle = "获取微信参数出错";
          });
      } else {
        this.getBaseInfos();
      }
    },
    getBaseInfos() {
      const currentUrl = window.location.href;
      const url = encodeURIComponent(currentUrl);
      const urlWithCode =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        payApi.AppID +
        "&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect";
      window.location.href = urlWithCode; // 打开这个链接，你的url后面就会跟上code的参数
    },
  },
  mounted() {
    this.getCode();
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.tutor {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 500px;
    width: auto;
    margin: 20px 18px;
    background: #ffffff;
    border-radius: 12px;
    align-items: center;
    .info-1 {
      font-weight: 500;
      font-size: 20px;
      color: #363637;
      letter-spacing: 3.92px;
      text-align: center;
      margin: 20px;
    }
    img {
      width: 220px;
      height: auto;
      margin: 0 0 20px 0;
    }
    .btn {
      display: flex;
      flex-direction: column;
      align-content: center;
      .copy {
        background: #ff8423;
        border-radius: 12px;
        width: 280px;
        height: 46px;
        border: none;
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 0.44px;
        font-weight: 500;
      }
      #copy-textarea {
        position: absolute;
        left: -99999px;
      }
    }
  }
  .on-loading {
    height: 500px;
    margin: 20px 18px;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    align-items: center;
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
