import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const qrcode = (id) => {
  return ajax.get(`${base}/api/itedu/v1/market/flex-qrcode?id=${id}`);
};

const recite = (id) => {
  return ajax.get(`${base}/api/itedu/v1/market/recite?id=${id}`);
};

const wxQrcode = (id, openid) => {
  return ajax.get(`${base}/api/itedu/v1/market/wx-qrcode?id=${id}&openid=${openid}`);
};

export const flexQrcodeApi = {
  // import时得花括号明确
  qrcode: qrcode,
  recite: recite,
  wxQrcode: wxQrcode,
};
